<template>
      <v-dialog v-model="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text justify-space-between" primary-title
          >Embarcación
          <v-btn class="float-right" icon dark @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px">
          <v-container v-if="modal">
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Datos de Registro</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.registration_number"
                    hint="Número de registro"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.boat_name"
                    hint="Nombre de la Embarcación"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      boat.registration_port ||
                      (
                        (lists.items_select_registration_port || []).find(
                          (x) => x.abbr == boat.registration_port
                        ) || {}
                      ).state
                    "
                    hint="Puerto de registro"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      boat.boat_type_state || (boat.boat_type || {}).nombre
                    "
                    hint="Tipo de barco"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="
                      boat.current_state_pavilion != null
                        ? lists.items_select_current_state_pavilion.find(
                            (x) => x.abbr == boat.current_state_pavilion
                          ) != undefined
                          ? lists.items_select_current_state_pavilion.find(
                              (x) => x.abbr == boat.current_state_pavilion
                            ).state
                          : ''
                        : ''
                    "
                    hint="Estado del pabellón"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="boat.owner_name"
                    hint="Propietario"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.cod_tracing"
                    hint="Código seguimiento"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Dimensiones</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.length_overall"
                    readonly
                    prepend-inner-icon
                    placeholder="ESLORA - Longitud total (LOA)(m)"
                    hint="ESLORA - Longitud total (LOA)(m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.calado"
                    readonly
                    prepend-inner-icon
                    placeholder="Calado / Proyecto (m)"
                    hint="Calado / Proyecto (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.beam_extreme_breadth"
                    readonly
                    prepend-inner-icon
                    placeholder="Manga / extrema amplitud (m)"
                    hint="Manga / extrema amplitud (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.net_registered_tonnage"
                    readonly
                    prepend-inner-icon
                    hint="Tonelaje de Registro Neto (TRN)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.gross_registered_tonnage"
                    prepend-inner-icon
                    readonly
                    hint="Tonelaje de Registro Bruto (TRB)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      (
                        (lists.items_select_propulsion_type || []).find(
                          (x) => x.abbr == boat.type_of_propulsion
                        ) || {}
                      ).state
                    "
                    readonly
                    hint="Tipo de propulsión."
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.main_engines_power"
                    readonly
                    prepend-inner-icon
                    placeholder="Potencia del motor principal/s"
                    hint="Potencia del motor principal/s"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.power_unit_state || boat.power_unit"
                    readonly
                    q21
                    º
                    hint="Unidad de Potencia"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      boat.hull_material_state ||
                      (
                        (lists.items_select_helmet_material || []).find(
                          (x) => x.abbr == boat.hull_material
                        ) || {}
                      ).state
                    "
                    readonly
                    hint="Material casco"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.warehouses_number"
                    readonly
                    prepend-inner-icon
                    placeholder="Numero de bodegas"
                    hint="Numero de bodegas"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.fish_hold_high"
                    readonly
                    prepend-inner-icon
                    placeholder="Alto de la bodega de pescado (m)"
                    hint="Alto de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.fish_hold_long"
                    readonly
                    prepend-inner-icon
                    placeholder="Largo de la bodega de pescado (m)"
                    hint="Largo de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.fish_hold_width"
                    readonly
                    placeholder="Ancho de la bodega de pescado (m)"
                    hint="Ancho de la bodega de pescado (m)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.fish_hold_capacity"
                    readonly
                    placeholder="Capacidad de la bodega de pescado (m³)"
                    hint="Capacidad de la bodega de pescado (m³)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="
                      boat.fish_hold_type_state ||
                      (
                        (
                          lists.items_select_type_conservation_fish || []
                        ).find((x) => x.abbr == boat.fish_hold_type) || {}
                      ).state
                    "
                    readonly
                    hint="Tipo de conservación del pescado"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Construcción</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="boat.year_of_construction"
                    hint="Año de construcción"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :value="boat.construction_country"
                    hint="País de construcción"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Patente de Pesca</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.date_expedition_patent"
                    hint="Fecha de inicio de vigencia de la patente"
                    persistent-hint
                    readonly
                    number
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="boat.date_expiration_patent"
                    hint="Fecha de vencimiento de la patente"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.number_of_crew"
                    hint="Numero de tripulantes"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :value="boat.number_of_fishermens"
                    hint="Numero de pescadores"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="(boat.type_fishery || []).length">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title
                            >Tipo de pesquería</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="item in boat.type_fishery"
                        :key="item.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.pivot.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-subheader class="font-weight-bold"
                    >Artes de Pesca</v-subheader
                  >
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Artes de Pesca</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="item in boat.fishing_arts != undefined
                          ? boat.fishing_arts.length > 0
                            ? boat.fishing_arts
                            : []
                          : []"
                        :key="item.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.pivot.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :value="boat.fishing_arts_value"
                    hint="Valor del arte de pesca ($)"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    :value="boat.fishing_zone"
                    hint="Área de operaciones"
                    persistent-hint
                    readonly
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      boat.fishing_arts.find((x) => x.codigo == "UNO") !=
                      undefined
                        ? boat.fishing_arts.find((x) => x.codigo == "UNO")
                            .nombre
                        : ""
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="
                      validValue(boat.fishing_line_state)
                        ? boat.fishing_line_state
                        : lists.items_select_type_line.find(
                            (x) => x.abbr == boat.fishing_line
                          ) != undefined
                        ? lists.items_select_type_line.find(
                            (x) => x.abbr == boat.fishing_line
                          ).state
                        : ''
                    "
                    hint="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="
                      validValue(boat.hook_type_state)
                        ? boat.hook_type_state
                        : lists.items_select_type_hook.find(
                            (x) => x.abbr == boat.hook_type
                          ) != undefined
                        ? lists.items_select_type_hook.find(
                            (x) => x.abbr == boat.hook_type
                          ).state
                        : ''
                    "
                    hint="Tipo de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    :value="boat.hook_size"
                    hint="Tamaño de anzuelo"
                    persistent-hint
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="boat.hooks_number"
                    hint="Cantidad de anzuelos"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="boat.mother_line_length"
                    hint="Longitud de la línea madre medida en brazas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      validValue(boat.mother_line_material_state)
                        ? boat.mother_line_material_state
                        : lists.items_select_mother_line_material.find(
                            (x) => x.abbr == boat.mother_line_material
                          ) != undefined
                        ? lists.items_select_mother_line_material.find(
                            (x) => x.abbr == boat.mother_line_material
                          ).state
                        : ''
                    "
                    hint="Material de la línea madre"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      validValue(boat.downpipe_material_state)
                        ? boat.mother_line_material_state
                        : lists.items_select_downspout_material.find(
                            (x) => x.abbr == boat.downpipe_material
                          ) != undefined
                        ? lists.items_select_downspout_material.find(
                            (x) => x.abbr == boat.downpipe_material
                          ).state
                        : ''
                    "
                    hint="Material de las bajantes"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'UNO') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="boat.total_lines_number"
                    hint="Cantidad total de líneas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      boat.fishing_arts.find((x) => x.codigo == "DOS") !=
                      undefined
                        ? boat.fishing_arts.find((x) => x.codigo == "DOS")
                            .nombre
                        : ""
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      boat.art_type_traps_state != null
                        ? boat.art_type_traps_state
                        : lists.items_select_denomination_art_fishing.find(
                            (x) => x.abbr == boat.art_type_traps
                          ) != undefined
                        ? lists.items_select_denomination_art_fishing.find(
                            (x) => x.abbr == boat.art_type_traps
                          ).state
                        : ''
                    "
                    hint="Denominación del arte de pesca tipo trampas o nasas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    v-model="boat.type_traps_number"
                    hint="Cantidad de trampas o nasas"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'DOS') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      boat.type_traps_material_state != null
                        ? boat.type_traps_material_state
                        : lists.items_select_main_trap_material.find(
                            (x) => x.abbr == boat.type_traps_material
                          ) != undefined
                        ? lists.items_select_main_trap_material.find(
                            (x) => x.abbr == boat.type_traps_material
                          ).state
                        : ''
                    "
                    hint="Material principal de la trampa o nasa"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-subheader
                    light
                    class="subtitle-1 font-italic font-weight-bold"
                  >
                    {{
                      (boat.fishing_arts.find((x) => x.codigo == "TRES") || {})
                        .nombre
                    }}
                  </v-subheader>
                  <v-divider light></v-divider>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      boat.type_wound_artifacts_state != null
                        ? boat.type_wound_artifacts_state
                        : lists.items_select_type_artifact.find(
                            (x) => x.abbr == boat.type_wound_artifacts
                          ) != undefined
                        ? lists.items_select_type_artifact.find(
                            (x) => x.abbr == boat.type_wound_artifacts
                          ).state
                        : ''
                    "
                    hint="Tipo de artefactos de herir o aferrar"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    v-model="boat.amount_artifacts"
                    label="Cantidad de artefactos"
                    hint="Cantidad de artefactos"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-show="
                    boat.fishing_arts.find((x) => x.codigo == 'TRES') !=
                    undefined
                  "
                >
                  <v-text-field
                    readonly
                    :value="
                      boat.artifacts_material_state != null
                        ? boat.artifacts_material_state
                        : lists.items_select_artifact_material.find(
                            (x) => x.abbr == boat.artifacts_material
                          ) != undefined
                        ? lists.items_select_artifact_material.find(
                            (x) => x.abbr == boat.artifacts_material
                          ).state
                        : ''
                    "
                    hint="Tipo de artefactos de herir o aferrar"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider light></v-divider>
                </v-col>
                <v-col cols="3" sm="12" md="3">
                  <v-text-field
                    readonly
                    :value="
                      validValue(boat.type_fad_state)
                        ? boat.type_fad_state
                        : lists.items_select_type_fad.find(
                            (x) => x.abbr == boat.type_fad
                          ) != undefined
                        ? lists.items_select_type_fad.find(
                            (x) => x.abbr == boat.type_fad
                          ).state
                        : ''
                    "
                    hint="Tipo de FAD utilizados"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="12" md="3">
                  <v-text-field
                    readonly
                    :value="boat.fad_amount"
                    hint="Cantidad de FAD utilizados"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-text-field
                    readonly
                    :value="boat.fad_components"
                    hint="Componentes del FAD"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
                <v-subheader class="font-weight-bold"
                  >Fotos de la Embarcación</v-subheader
                >
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-gallery
                  :images="images"
                  :index="index"
                  @close="index = null"
                >
                </v-gallery>
              </v-col>
              <v-col
                cols
                class="image"
                v-for="(image, imageIndex) in images"
                :key="imageIndex"
                @click="index = imageIndex"
                :style="{
                  backgroundImage: 'url(' + image + ')',
                  width: '300px',
                  height: '150px',
                  backgroundSize: 'contain',
                }"
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            :title="boat.status != 'active' ? 'No tiene patente activa' : ''"
            :disabled="boat.status != 'active'"
            text
            @click="$emit('handlePDF', 'Ok')"
            >Descargar PDF Ult. Patente</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { boatImageUrl } from '../config';
export default {
 props: ['modal', 'boat', 'lists'],
 computed: {
    images() {
      const images = this.boat.images;
      const array_i = [];
      images.map((x) => {
        array_i.push(this.path + x.nombre);
      });
      return array_i;
    },
 },
 data(){
  return {
   index: null,
   path: boatImageUrl,

  }
 },
 methods: {
  validValue(value){ 
    return Boolean(!value && (value === '{"abbr":null,"state":null}' || '{"nombre":null,"codigo":null}'));
  }
 }
}
</script>

<style>

</style>