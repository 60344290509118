<template>
  <v-container grid-list-sm>
    <v-layout row wrap>
      <v-flex xs12 class="mb-4">
          <v-alert v-show="showAlertPatent"  type="info" dismissible
            >La embarcación ya tiene una patente vigente</v-alert
          >
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >Procedimiento
          {{ this.getRequestType(type_request) || null }}</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>

      <v-flex xs6>
        <v-select
          v-model="location"
          prepend-inner-icon="map"
          hint="Lugar de la solicitud"
          :items="items_location"
          item-text="state"
          item-value="abbr"
          label="Lugar de la solicitud"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </v-flex>

      <v-flex xs6>
        <v-menu
          v-model="menuExpeditionDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_expedition"
              hint="Fecha de expedición"
              persistent-hint
              prepend-icon="event"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_expedition"
            @input="menuExpeditionDate = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >DATOS DE LA SOLICITUD</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="filed"
          prepend-inner-icon="description"
          placeholder="Radicado"
          hint="#Radicado"
          clearable
          persistent-hint
        ></v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-menu
          v-model="menu_date_filing"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_filing"
              hint="Fecha de radicado"
              persistent-hint
              prepend-icon="event"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_filing"
            @input="menu_date_filing = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs6>
        <v-menu
          v-model="menu_date_application_assignment"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_application_assignment"
              hint="Fecha Asignación de la solicitud"
              persistent-hint
              prepend-icon="event"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_application_assignment"
            @input="menu_date_application_assignment = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs6 v-if="title == 'CT' && type == 'ind'">
        <v-combobox
          v-model="select_authorized_type"
          :items="items_select_authorized_type"
          hide-selected
          item-text="state"
          item-value="abbr"
          hint="Tipo de autorización - Si el tipo de autorización no está en la lista, escríbalo y de click en ENTER"
          label
          persistent-hint
          small-chips
        ></v-combobox>
      </v-flex>

      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >DOCUMENTOS DE LA SOLICITUD</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex xs3>
        <p>Carta de solicitud</p>
        <v-radio-group v-model="radiosApplicationLetter" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Cámara de comercio</p>
        <v-radio-group v-model="radiosChamberOfCommerce" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Copia del documento de identificación</p>
        <v-radio-group v-model="radiosCopyIdentificationDocument" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT' && type == 'art'">
        <p>Copia de la ocre</p>
        <v-radio-group v-model="radiosCopyOCcre" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Plan de actividades</p>
        <v-radio-group v-model="radiosActivityPlan" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Fotocopia tarjeta o matrícula profesional</p>
        <v-radio-group v-model="radiosCopyProfessionalRegistration" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT' && type == 'ind'">
        <p>Certificación empresa que procesara las capturas</p>
        <v-radio-group v-model="certificado_captura" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Copia de la matrícula de la motonave(s)</p>
        <v-radio-group v-model="radiosMotorcycleRegistrationCopy" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT' && type == 'art'">
        <p>Contrato de afiliación de la motonave(s)</p>
        <v-radio-group v-model="radiosMotorBoatAffiliationContract" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs3 v-if="title == 'CT'">
        <p>Infome anual de actividades</p>
        <v-radio-group v-model="radiosAnnualActivityReport" row>
          <v-radio label="Si" value="SI"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
          <v-radio label="N/A" value="NA"></v-radio>
        </v-radio-group>
      </v-flex>
      <!-- <v-flex xs3>
            <p>Visita técnica de motonave</p>
            <v-radio-group  v-model="radiosMotorInspection" row>
              <v-radio label="Si" value="SI"></v-radio>
              <v-radio label="No" value="NO"></v-radio>
              <v-radio label="N/A" value="NA"></v-radio>
            </v-radio-group>
          </v-flex> -->
      <v-flex xs12 class="mb-4">
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >DATOS BÁSICOS DEL PERMISIONARIO</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex xs12 v-if="type_request === abreviatureTypeRequest.PATENTE_PESCA && type == 'art'">
        <v-radio-group
          v-model="patent_type"
          row
        >
          <v-radio
            v-if="this.company_id !== null"
            label="Compañia / Organización"
            value="company"
          ></v-radio>
          <v-radio
            label="Pescador"
            value="fisherman"
          ></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs12 v-if="patent_type === 'fisherman'">
        <v-select
          :items="fishermen"
          item-value="id"
          item-text="full_name"
          label="Pescadores"
          v-model="fisherman_id"
        ></v-select>
      </v-flex>
      <v-flex xs12 v-else-if="patent_type === 'organization'">
        <v-select
          v-model="organization_id"
          :items="allOrganizations"
          item-text="abbr_name"
          item-value="id"
          hint="Nombre de la organización"
          persistent-hint
          small-chips
        ></v-select>
      </v-flex>
      <v-layout row v-else>
        <v-flex xs4>
          <v-text-field
            v-model="name"
            :disabled="company_id != null"
            placeholder="Nombre"
            hint="Nombre"
            clearable
            persistent-hint
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="nit"
            :disabled="company_id != null"
            placeholder="NIT"
            hint="NIT"
            clearable
            persistent-hint
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <template >
            <v-text-field
              v-model="cod_nit"
              :disabled="cod_nit_validate != null"
              placeholder="Dígito de verificación"
              hint="Digíto de verificación"
              clearable
              persistent-hint
            ></v-text-field>
          </template>
        </v-flex>
      </v-layout>
      <v-flex
        xs12
        class="mb-4 mt-4"
        v-if="type_request == abreviatureTypeRequest.PRORROGA"
      >
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >PRORROGA DEL PERMISO DE PESCA</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex
        xs6
        class="mb-4"
        v-if="type_request == abreviatureTypeRequest.PRORROGA"
      >
        <v-select
          v-model="select_fishinng_permission_validity"
          prepend-inner-icon="fa-industry"
          hint="Vigencia del permiso de pesca comercial (años)"
          :items="items_select_fishinng_permission_validity"
          item-text="nombre"
          item-value="codigo"
          label="Vigencia del permiso de pesca comercial (años)"
          persistent-hint
          return-object
          single-line
        ></v-select>
      </v-flex>
      <v-flex
        xs12
        class="mb-4"
        v-if="type_request != 'c' && type_request != 'pp'"
      >
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >EMBARCACIONES</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex
        xs12
        v-if="
          type_request != 'd' && type_request != 'c' && type_request != 'pp'
        "
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search_boat"
                    hint="Buscar Embarcación"
                    label="Número de matrícula o nombre de la embarcación"
                    @keyup="searchBoat"
                    prepend-icon="search"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group active-class="border" color="indigo">
                      <v-list-item v-for="(item, i) in boats_unlinked" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="
                              item.registration_number + '-' + item.boat_name
                            "
                            @click="setBoatUnlinked(item)"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <span>{{ messageNotFoundBoat }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex
        xs12
        v-if="
          type_request == abreviatureTypeRequest.NUEVO ||
          type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION ||
          type_request == abreviatureTypeRequest.PRORROGA
        "
      >
        <v-subheader light class="subtitle-1 font-italic font-weight-bold"
          >Agregar nuevas embarcaciones</v-subheader
        >
        <v-divider light></v-divider>
      </v-flex>
      <v-flex
        xs5
        v-if="
          type_request == abreviatureTypeRequest.NUEVO ||
          type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.REACTIVACION_PERMISO ||
          type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION ||
          type_request == abreviatureTypeRequest.PRORROGA
        "
      >
        <v-text-field
          v-model="boat_name"
          placeholder="Nombre"
          hint="Nombre"
          clearable
          persistent-hint
        ></v-text-field>
      </v-flex>
      <v-flex
        xs5
        v-if="
          type_request == abreviatureTypeRequest.NUEVO ||
          type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.REACTIVACION_PERMISO ||
          type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION ||
          type_request == abreviatureTypeRequest.PRORROGA
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="number_boat"
              placeholder="N° Matrícula"
              hint="N° Matrícula"
              clearable
              @keyup="Valid()"
              v-bind="attrs"
              v-on="on"
              persistent-hint
            ></v-text-field>
          </template>
          <span v-if="!matricula_valid"
            >La matrícula no debe contener caracteres especiales ni espacios
            ejemplo: (CP07002)</span
          >
        </v-tooltip>
      </v-flex>
      <v-flex
        xs2
        v-if="
          type_request == abreviatureTypeRequest.NUEVO ||
          type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.REACTIVACION_PERMISO ||
          type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION ||
          type_request == abreviatureTypeRequest.PRORROGA
        "
      >
        <v-btn
          color="green"
          class="mt-5 ml-2 white--text"
          :disabled="!matricula_valid"
          @click="addBoat()"
          >Agregar</v-btn
        >
      </v-flex>
      <v-alert
        width="100%"
        class="mt-3"
        type="error"
        dismissible
        v-if="alertBoat"
        >Solo puede vincular una embarcacion</v-alert
      >
      <v-alert width="100%" class="mt-3" type="error" v-if="alertInspection">{{
        dataInspection
      }}</v-alert>
      <v-flex
        xs12
        class="mt-5"
        v-if="
          type_request == abreviatureTypeRequest.NUEVO ||
          type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.REACTIVACION_PERMISO ||
          type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION ||
          type_request == abreviatureTypeRequest.PRORROGA
        "
      >
        <v-simple-table :dense="false" :fixed-header="true" height="200px">
          <template v-slot:default>
            <thead>
              <tr>
                <th id="nombre_b">Nombre</th>
                <th id="matricula_b">N° Matricula</th>
                <th id="space_b"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in boats" :key="index">
                <td>{{ item.boat_name }}</td>
                <td>{{ item.registration_number }}</td>
                <td class="float-right">
                  <v-btn small class="primary" @click="removeBoat(index)"
                    >Eliminar</v-btn
                  >
                </td>
              </tr>
              <tr
                v-if="
                  type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION
                "
              >
                <td colspan="3">Embarcación a Desvincular</td>
              </tr>
              <tr
                v-if="
                  type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION
                "
              >
                <td>{{ boat.boat_name }}</td>
                <td>{{ boat.registration_number }}</td>
                <td class="float-right">
                  <v-checkbox
                    title="Checkear"
                    v-model="boat"
                    color="indigo"
                    disabled
                    :value="boat"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>

      <v-flex
        xs12
        class="mt-5"
        v-if="
          type_request == abreviatureTypeRequest.DESVINCULACION_EMBARCACION ||
          type_request == abreviatureTypeRequest.PATENTE_PESCA
        "
      >
        <v-simple-table :dense="false" :fixed-header="true" height="200px">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>N° Matricula</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in boats" :key="item.registration_number">
                <td>{{ item.boat_name }}</td>
                <td>{{ item.registration_number }}</td>
                <td class="float-right" @click="checkBoat">
                  <v-checkbox
                    title="Checkear"
                    v-model="checkBoats"
                    color="indigo"
                    :disabled="
                      type_request == abreviatureTypeRequest.PATENTE_PESCA
                    "
                    :value="item.id"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>

      <v-flex xs12>
        <v-btn
          @click="save()"
          class="indigo darken-4 white--text float-center"
          large
          v-if="valid_rad"
          >Guardar</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import swal from "sweetalert";

import { saveRadicado, getHeader } from "../config";
import { abreviatureTypeRequest } from "../enums/requestTypes";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    fishermen : [],
    showAlertPatent: false,
    patent_type : 'company',
    organization_id : '',
    fisherman_id: '',
    location: "",
    filed: "",
    alertBoat: false,
    certificado_captura: null,
    alertInspection: false,
    dataInspection: "",
    valid_rad: true,
    valid_inspection_boat: [],
    checkBoats: [],
    matricula_valid: false,
    abreviatureTypeRequest: abreviatureTypeRequest,
    items_location: [
      { state: "1. San Andrés", abbr: "UNO" },
      { state: "2. Providencia y Santa Catalina", abbr: "DOS" },
    ],
    select_authorized_type: { state: "", abbr: "" },
    items_select_authorized_type: [
      { state: "1. Pesca Comercial Industrial", abbr: "UNO" },
      { state: "2. Permiso Integrado de Pesca", abbr: "DOS" },
    ],
    select_fishinng_permission_validity: { nombre: "", codigo: "" },
    items_select_fishinng_permission_validity: [
      { nombre: "1", codigo: "UNO" },
      { nombre: "2", codigo: "DOS" },
      { nombre: "3", codigo: "TRES" },
      { nombre: "4", codigo: "CUATRO" },
      { nombre: "5", codigo: "CINCO" },
    ],
    menu_date_effective_authorization_init: false,
    menu_date_effective_authorization_finish: false,
    date_effective_authorization_init: new Date().toISOString().substr(0, 10),
    date_effective_authorization_finish: new Date().toISOString().substr(0, 10),
    date_expedition: new Date().toISOString().substr(0, 10),
    date_filing: new Date().toISOString().substr(0, 10),
    date_application_assignment: new Date().toISOString().substr(0, 10),
    menuExpeditionDate: false,
    menu_date_filing: false,
    menu_date_application_assignment: false,
    radiosApplicationLetter: "",
    radiosChamberOfCommerce: "",
    radiosCopyIdentificationDocument: "",
    radiosCopyOCcre: "",
    radiosActivityPlan: "",
    radiosCopyProfessionalRegistration: "",
    radiosMotorcycleRegistrationCopy: "",
    radiosMotorBoatAffiliationContract: "",
    radiosMotorInspection: "SI",
    radiosAnnualActivityReport: "",
    boat_name: "",
    number_boat: "",
    boats: [],
    name: "",
    nit: "",
    cod_nit: "",
    cod_nit_validate: null,
    type: "",
    type_request: "",
    permission_id: null,
    search_boat: "",
    boats_unlinked: [],
    messageNotFoundBoat: "",
    title: "",
    boat: null,
    company_id: null,
  }),
  methods: {
    ...mapActions(['getOrganizations']),
    getRequestType($type) {
      switch ($type) {
        case abreviatureTypeRequest.NUEVO:
          return "Nuevo Permiso";
        case abreviatureTypeRequest.PRORROGA:
          return "Prorroga Permiso";
        case abreviatureTypeRequest.VINCULACION_EMBARCACION:
          return "Vinculación M/N";
        case abreviatureTypeRequest.DESVINCULACION_EMBARCACION:
          return "Desvinculación M/N";
        case abreviatureTypeRequest.REMPLAZO_EMBARCACION:
          return "Reemplazo M/N";
        case abreviatureTypeRequest.CANCELACION_PERMISO:
          return "Cancelación Permiso";
        case abreviatureTypeRequest.PATENTE_PESCA:
          return "Patente M/N";
        case abreviatureTypeRequest.REACTIVACION_PERMISO:
          return "Reactivación Permiso";
      }
    },
    searchBoat() {
      this.messageNotFoundBoat = "";
      let unlinked = [];
      this.boats_unlinked = [];

      this.$store.getters.UnlinkedBoats.forEach((boat) => {
        if (boat.boat_name != null) {
          if (
            boat.boat_name
              .toUpperCase()
              .includes(this.search_boat.toUpperCase()) &&
            boat.type_boat == this.type.toUpperCase()
          ) {
            unlinked.push(boat);
          }
        }
        if (boat.registration_number != null) {
          if (
            boat.registration_number
              .toUpperCase()
              .includes(this.search_boat.toUpperCase()) &&
            boat.type_boat == this.type.toUpperCase()
          ) {
            unlinked.push(boat);
          }
        }
      });

      this.boats_unlinked = unlinked.slice(0, 15);
      if (this.boats_unlinked.length == 0)
        this.messageNotFoundBoat =
          " No se encontró ninguna embarcación sin vincular";
    },
    Valid() {
      const pattern = /^[a-zA-Z0-9]+$/;
      this.number_boat = this.number_boat.toUpperCase();
      this.matricula_valid = pattern.test(this.number_boat);
    },
    checkBoat() {
      if (
        this.type_request == abreviatureTypeRequest.PATENTE_PESCA &&
        this.checkBoats.length > 1
      ) {
        this.checkBoats.pop();
      }
    },
    setBoatUnlinked(item) {
      if (
        this.type_request == abreviatureTypeRequest.VINCULACION_EMBARCACION &&
        this.boats.length == 1
      ) {
        this.alertBoat = true;
        setTimeout(() => {
          this.alertBoat = false;
        }, 3000);
        this.search_boat = "";
        this.boats_unlinked = [];
        return false;
      }
      if (
        this.boats.find(
          (x) => x.registration_number == item.registration_number
        )
      ) {
        this.search_boat = "";
        return;
      }
      this.boats.push({
        id: item.id,
        boat_name: item.boat_name,
        registration_number: item.registration_number,
      });
      this.search_boat = "";
      this.boats_unlinked = [];
    },
    addBoat() {
      if (
        this.type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION &&
        this.boats.length == 1
      ) {
        this.alertBoat = true;
        setTimeout(() => {
          this.alertBoat = false;
        }, 3000);
        this.boat_name = "";
        this.number_boat = "";
        return false;
      }
      if (this.boats.find((x) => x.registration_number == this.number_boat))
        return;
      this.boats.push({
        id: null,
        boat_name: this.boat_name,
        registration_number: this.number_boat,
      });
      this.boat_name = "";
      this.number_boat = "";
    },
    removeBoat(key) {
      this.boats.splice(key, 1);
    },
    setData(action, item, title, type) {
      this.showAlertPatent = false;
      if(this.getRequestType(action) == 'Patente M/N')
      {
        if(item.status !== "noPatent")
          this.showAlertPatent = true;
      }
      this.title = title;
      this.boats = [];
      this.fishermen = type.toUpperCase() === 'IND' ? item.fishermans_ind : item.fishermans;
      
      if (action != "UNO") {
        //prorroga
        if (
          action == abreviatureTypeRequest.PATENTE_PESCA ||
          action == abreviatureTypeRequest.REMPLAZO_EMBARCACION
        ) {
          this.$store.dispatch("getUnlinked");
          this.name = item.company != null ? item.company.name : null;
          this.nit = item.company != null ? item.company.nit : null;
          this.cod_nit = item.company != null ? item.company.cod_nit : null;
          this.cod_nit_validate = item.company && item.company != null ? item.company.cod_nit : null;
          this.company_id = item.company != null ? item.company.nit : null;
        } else {
          this.name = item.name;
          this.nit = item.nit;
          this.company_id = item.nit;
          this.cod_nit = item.cod_nit;
          this.cod_nit_validate = item.cod_nit == undefined || item.cod_nit == '' ? null : item.cod_nit;
        }
      } else {
        this.name = null;
        this.nit = null;
        this.company_id = null;
      }
      if (action == abreviatureTypeRequest.PRORROGA) {
        /*console.log(item.permissions);
        this.date_effective_authorization_init = new Date(
          item.permissions.slice(-1)[0].effective_activation_date
        )
          .toISOString()
          .substr(0, 10);
        this.date_effective_authorization_finish = new Date(
          item.permissions.slice(-1)[0].effective_inactivation_date
        )
          .toISOString()
          .substr(0, 10);
        this.permission_id = item.permissions.slice(-1)[0].id;*/
      } else if (
        action == abreviatureTypeRequest.VINCULACION_EMBARCACION ||
        action == "UNO"
      ) {
        this.$store.dispatch("getUnlinked");
      } else if (
        action == abreviatureTypeRequest.PATENTE_PESCA ||
        action == abreviatureTypeRequest.REMPLAZO_EMBARCACION
      ) {
        if (action == abreviatureTypeRequest.REMPLAZO_EMBARCACION) {
          this.boat = item;
        } else {
          this.boats = [item];
          this.checkBoats.push(item.id);
        }
      } else if (action == abreviatureTypeRequest.DESVINCULACION_EMBARCACION) {
        item.boats.forEach((x) => {
          this.boats.push({
            id: x.id,
            boat_name: x.boat_name,
            registration_number: x.registration_number,
          });
        });
      }

      if (
        action === abreviatureTypeRequest.REACTIVACION_PERMISO ||
        action == abreviatureTypeRequest.PRORROGA
      ) {
        this.$store.dispatch("getUnlinked");
        item.boats.forEach((x) => {
          this.boats.push({
            id: x.id,
            boat_name: x.boat_name,
            registration_number: x.registration_number,
          });
        });
      }

      this.type_request = action;
      this.type = type;
      if(this.type_request == 'UNO')
      {
        this.patent_type = ''
      }
      else
      { 
        this.patent_type = this.company_id !== null ? '' : 'fisherman';
      }
    },
    save() {

      let selectedBoats;
      if (
        this.type_request == abreviatureTypeRequest.PATENTE_PESCA ||
        this.type_request == abreviatureTypeRequest.DESVINCULACION_EMBARCACION
      ) {
        selectedBoats = this.checkBoats;
      } else {
        selectedBoats = this.boats;
      }
      let postData = {
        patent_type : this.patent_type,
        organization_id : this.organization_id,
        fisherman_id : this.fisherman_id,
        location: this.location.abbr,
        filed: this.filed,
        expedition_date: this.date_expedition,
        date_filing: this.date_filing,
        date_application_assignment: this.date_application_assignment,
        type_of_authorization: this.select_authorized_type.abbr,
        request_type:
          this.type_request == abreviatureTypeRequest.NUEVO
            ? "UNO"
            : this.type_request == abreviatureTypeRequest.PRORROGA
            ? "DOS"
            : this.type_request ==
              abreviatureTypeRequest.VINCULACION_EMBARCACION
            ? "TRES"
            : this.type_request ==
              abreviatureTypeRequest.DESVINCULACION_EMBARCACION
            ? "CUATRO"
            : this.type_request == abreviatureTypeRequest.REMPLAZO_EMBARCACION
            ? "CINCO"
            : this.type_request == abreviatureTypeRequest.CANCELACION_PERMISO
            ? "SEIS"
            : this.type_request == abreviatureTypeRequest.PATENTE_PESCA
            ? "SIETE"
            : this.type_request == abreviatureTypeRequest.REACTIVACION_PERMISO
            ? "OCHO"
            : null,
        type: this.type.toUpperCase(),
        request_letter: this.radiosApplicationLetter,
        chamber_of_commerce: this.radiosChamberOfCommerce,
        identification_copy_doc: this.radiosCopyIdentificationDocument,
        ocre_copy: this.radiosCopyOCcre,
        certificado_captura: this.certificado_captura,
        activities_plan: this.radiosActivityPlan,
        photocopy_professional_card: this.radiosCopyProfessionalRegistration,
        motorcycle_enrollment_copy: this.radiosMotorcycleRegistrationCopy,
        motorcycle_affiliation_cont: this.radiosMotorBoatAffiliationContract,
        annual_activity_report: this.radiosAnnualActivityReport,
        motorcycle_technical_visit: this.radiosMotorInspection,
        nit: this.nit,
        cod_nit: this.cod_nit,
        cod_nit_validate: this.cod_nit == '' || this.cod_nit == undefined  ? null : this.cod_nit,
        name: this.name,
        boat: this.boat ? this.boat.id : null,
        selectedBoats: selectedBoats,
        duration_of_permit_granted:
          this.type_request == abreviatureTypeRequest.PRORROGA
            ? this.select_fishinng_permission_validity.codigo
            : null,
        permission_id: this.permission_id,
      };

      this.axios
        .post(saveRadicado, postData, { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ path: "/requests-companies-" + this.type });
          }
        })
        .catch((error) => {
          if (error.response.status === 421) {
            swal({
              title: "Error!",
              text: "" + error.response.data["errors"]["message"] + "",
              icon: "error",
            });
          }
        });
    },
  },
  computed : {
    ...mapGetters(['allOrganizations']),
  },
  created(){
    this.getOrganizations();
  }
};
</script>
