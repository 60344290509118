import { consultar } from "./variablesconcepto";
import BaseService from "./BaseService";

export default async function getFields() {
  const concepto = await consultar.fetch();
  let fields = {
    json_fields: {
      "Marcador Externo - Número de Matricula*": "registration_number",
      Permisionario: {
        callback: (value) => {
          if (value.company != null) {
            return `${value.company.name}`;
          } else {
            return "SIN PERMISIONARIO";
          }
        },
      },
      NIT: {
        callback: (value) => {
          if (value.company != null) {
            return `${value.company.nit}-${
              value.company.cod_nit != null ? value.company.cod_nit : ""
            } `;
          } else {
            return "";
          }
        },
      },
      "Tipo de pesquería": {
        callback: (value) => {
          if (value.company != null) {
            const permission = value.company.permissions.slice(-1).pop();
            var types = "";
            let i = 1;
            if (permission != undefined) {
              permission.type_fishery.map((x) => {
                types += `${x.pivot.description}`;
                if (i < permission.type_fishery.length) {
                  types += `, `;
                }
                i++;
              });
            }
            return `${types}`;
          } else {
            return "";
          }
        },
      },
      "identificación Embarcación": {
        field: "boat_unique_id_state",
        callback: (value) => {
          if (value != null) {
            var boat_u = concepto.items_boat_unique_id.find(
              (x) => x.abbr == value
            );
            if (boat_u != undefined) {
              return concepto.items_boat_unique_id.find((x) => x.abbr == value);
            } else {
              return value;
            }
          }
        },
      },
      "Nombre de la embarcación": "boat_name",
      "Lugar de expedición de la matricula": "expedition_place",
      "Fecha de expedición de la matricula": "expedition_date",
      "Fecha de expiración de la matricula": "expiration_date",
      "Estado actual del pabellón": {
        field: "current_state_pavilion",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_current_state_pavilion.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Puerto de Registro": {
        field: "registration_port",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_registration_port.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_registration_port.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Tipo de Barco": "boat_type.nombre",
      "Valor aproximado de la embarcación": "approx_boat_value",
      "Vendera los productos a": "sell_products_to",
      "ESLORA - Longitud total (LOA)(m)": "length_overall",
      "Manga / extrema amplitud (m)": "beam_extreme_breadth",
      "Tonelaje de Registro Neto (TRN)": "net_registered_tonnage",
      "Tonelaje de Registro Bruto (TRB)": "gross_registered_tonnage",
      "Tipo de Propulsion": {
        field: "type_of_propulsion",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_propulsion_type.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_propulsion_type.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Potencia del motor principal/s": "main_engines_power",
      "Unidad de Potencia": {
        field: "power_unit",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_power_unit.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_power_unit.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Material casco": {
        field: "hull_material",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_helmet_material.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_helmet_material.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Numero de bodegas": "warehouses_number",
      "Alto de la bodega de pescado (m)": "fish_hold_high",
      "Largo de la bodega de pescado (m)": "fish_hold_long",
      "Ancho de la bodega de pescado (m)": "fish_hold_width",
      "Capacidad de la bodega de pescado (m³)": "fish_hold_capacity",

      "Tipo de conservación del pescado": {
        field: "fish_hold_type",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_type_conservation_fish.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_type_conservation_fish.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Año de construcción": "year_of_construction",
      "País de construcción": "construction_country",
      "Fecha de inicio de vigencia de la patente": "date_expedition_patent",
      "Fecha de vencimiento de la patente": "date_expiration_patent",
      "Vigencia de la patente(meses)": "validity_of_patent",
      "Numero de tripulantes": "number_of_crew",
      "Numero de pescadores": "number_of_fishermens",

      "Artes de Pesca": {
        field: "fishing_arts",
        callback: (value) => {
          var artes = "";
          var i = 1;
          if (value != null) {
            value.map((x) => {
              artes += `${x.pivot.description}`;
              if (i < value.length) {
                artes += `, `;
              }
              i++;
            });
          }
          return `${artes}`;
        },
      },
      "Area de Operaciones": "fishing_zone",

      "Puerto de desembarque": {
        callback: (value) => {
          if (value.fishing_port != null) {
            let items;
            if (value.type_boat == "IND")
              items = concepto.items_select_authorized_landing_port_ind;
            else items = concepto.items_select_authorized_landing_port;
            return (items.find((x) => x.codigo == value.fishing_port) || {})
              .nombre;
          } else {
            return value.fishing_port_state;
          }
        },
      },

      "Tipo de Anzuelo": {
        field: "hook_type",
        callback(value) {
          if (value != null) {
            return value == 1 ? "Jota" : "Circular";
          }
        },
      },
      "Tamaño y tipo de anzuelo": "hook_size",
      "Cantidad de anzuelos": "hooks_number",
      "Longitud de la linea madre": "mother_line_length",
      "Material de la linea madre": {
        field: "mother_line_material",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_mother_line_material.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_mother_line_material.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Material de las bajantes": {
        field: "downpipe_material",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_downspout_material.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_downspout_material.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Cantidad total de lineas": "total_lines_number",
      "Denominación del tipo de pesca arte tipo trampa o nasas": {
        field: "art_type_traps",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_denomination_art_fishing.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_denomination_art_fishing.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Cantidad de trampas o nasas": "type_traps_number",

      "Material principal de trampa o nasas": {
        field: "type_traps_material",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_main_trap_material.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_main_trap_material.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Tipo de artefactos de herir o aferrar": {
        field: "type_wound_artifacts",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_type_artifact.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_type_artifact.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Cantidad de artefactos": "amount_artifacts",
      "Material de  artefactos": {
        field: "artifacts_material",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_artifact_material.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_artifact_material.find(
                (x) => x.abbr == value
              ).state;
            } else {
              return value;
            }
          }
        },
      },
      "Tipo de FAD utilizados": {
        field: "type_fad",
        callback(value) {
          if (value != null) {
            let campo = concepto.items_select_type_fad.find(
              (x) => x.abbr == value
            );
            if (campo != undefined) {
              return concepto.items_select_type_fad.find((x) => x.abbr == value)
                .state;
            } else {
              return value;
            }
          }
        },
      },
      "Cantidad de FAD utilizados": "fad_amount",
      "Componentes des FAD": "fad_components",
      "Cartas de navegación": "navigation_letters",
      "Compas Magnetico": "magnetic_compass",
      GPS: "gps",
      Loran: "loran",
      Radar: "radar",
      Ecosonda: "eco_probe",
      "Radios de comunicación": "communication_radios",
    },
  };
  return fields;
}

export function getBoats(type) {
  return BaseService.get(`all-boats/${type}`);
}

export function getBoatsUser(userId,type,paginate,buscar='') {
  return BaseService.get(`all-boats-user/${userId}/${type}?page=${paginate}&buscar=${buscar}`);
}

export function getBoatsRelaUser(userId) {
  return BaseService.get(`all-boats-user/${userId}`);
}

export function syncBoatsByUser(userId, data){
  return BaseService.put(`permission/user/assing-boats/${userId}`, data);
}