<template>
  <div>
    <v-dialog :value="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title
          class="headline indigo darken-4 white--text justify-space-between"
          primary-title
          >Historial de actividades
          <v-btn class="float-right" icon dark @click="$emit('handleClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px">
          <v-container>
            <v-row>
               <v-simple-table fixed-header style="width:100%">
              <template id="customers" v-slot:default>
                <thead>
                   <tr>
                  <td >Actividad</td>
                  <td>Responsable</td>
                  <td>Fecha de actividad</td>
                </tr>
                </thead>
               <tbody>
                  <tr v-for="log in logs" :key="log.id">
                  <td> {{log.description}} <br> <small>{{ log.created_at | formatAgo}} </small></td>
                  <td v-text="log.created_by"></td>
                  <td>  {{ log.created_at | formatDefault}}</td>
                </tr>
               </tbody>
               
              </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>

<script>
import { getActivityLog } from "../config.js";
export default {

  props: ["modal","type","consecutive", "process"],
  data: () => ({
    logs: []
  }),
  computed: {
    
  },
  methods: {
    consultarLogs(){
      this.axios
        .get(getActivityLog+'?table_process_main='+this.process+this.type+'&id_table_process_main='+this.consecutive)
        .then((response) => {
          this.logs = response.data;
        })
    }
  },
  mounted() {
         // this.$store.commit('LOADER', false);
    this.$store.commit("STOP_LOADER");
    this.consultarLogs();
  }
};
</script>
