<template>
  <div class="overflow-hidden" v-show="type != 'dep'">
    <v-bottom-navigation
      v-model="value"
      :input-value="active"
      color="indigo"
    >
      <template v-for="(item, index) in items">
        <v-btn :key="index" :to="{path: item.redirect+type}">
          <span>{{item.text}}</span>
          <v-icon small>{{item.icon}}</v-icon>
        </v-btn>
      </template>
      <template v-show="type == 'art'" v-if="$canArray(['verListadosConDetalle'])">
        <v-btn :to="{path: 'organizations'}">
          <span>Organizaciones</span>
          <v-icon small>mdi-city-variant</v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  name: "ToggleNavigation",
  props: ['items', 'type'],
  data(){
   return {
      
   }
  },
  methods: {
    
  },
};
</script>

<style  scoped>
</style>
