<template>
  <div>
    <ToggleNavigation :items="items" :type="type"></ToggleNavigation>
    <ModalInfoBoat
      v-on:handlePDF="generatePDF"
      :lists="concepto"
      :modal="modal"
      :boat="barco"
      @onClose="modal = false"
    ></ModalInfoBoat>
    <v-dialog v-model="modal_matricula" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text" primary-title
          >Modificar Información Básica</v-card-title
        >
        <v-card-text style="height: 220px">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="nombre_boat"
                  label="Nombre Embarcación"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="matricula_add"
                  :disabled="
                    barco.registration_number != null &&
                    barco.registration_number != ''
                  "
                  label="No de Matrícula"
                  @keyup="Valid()"
                ></v-text-field>
                <span v-if="!matricula_valid"
                  >La matrícula no debe contener caracteres especiales ni
                  espacios ejemplo: (CP07002)</span
                >
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="cod_tracing"
                  label="Código de Seguimiento"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-btn
                  color="green"
                  class="mt-5 ml-2 white--text"
                  :disabled="!matricula_valid"
                  @click="editBoat()"
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="modal_matricula = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Este permisionario ya tienen una solicitud a la espera de ser resuelta
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <boats-table 
      :type="type"
      @onShowBoat="seeItem"
      @onRequest="Peticion"
      @onAddInformation="setItem"
    ref="boatsTable"
    ></boats-table>
    <v-dialog eager v-model="dialogRad" scrollable persistent width="1200px">
      <v-card>
        <v-card-text>
          <v-container>
            <ComponentRadicado ref="setDataRad"></ComponentRadicado>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogRad = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { consultar, concepto } from "../services/variablesconcepto";
import ToggleNavigation from "../components/ToggleNavigation";
import ModalInfoBoat from "../components/ModalInfoBoat";
import { navegacion } from "../services/exportNavigation";
import {
  editBoatUrl,
  findRequestBoat,
  getBoatPath,
  getHeader,
} from "../config";
import axios from "axios";
import BoatsTable from "../components/BoatsTable.vue";
import ComponentRadicado from '../components/ComponentRadicado.vue';
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import escudogobernacion from "../assets/media/rpg/escudogobernacion.png";
import watermark from "../assets/media/rpg/watermark.png";
import { getConceptTechnicalArtPatent, firmaUrl } from "../config.js";
import { requestables } from "../enums/requestables";
import { convert } from "../services/numToWord";

export default {
  props: ["type"],
  components: {
    ModalInfoBoat,
    BoatsTable,
    ComponentRadicado,
    ToggleNavigation
  },
  data: () => ({
    items: [],
    modal: false,
    modal_matricula: false,
    barco: { fishing_arts: [], images: [] },
    json_fields: {},
    concepto: concepto,
    searchBoat: "",
    index: null,
    dialog: false,
    dialogRad: false,
    snackbar: false,
    desserts: [],
    editedIndex: -1,
    filtrado: [],
    matricula_add: "",
    cod_tracing: "",
    nombre_boat: "",
    _id: "",
    currentPage: 1,
    matricula_valid: false,
    requestables: [],
    convert: convert
  }),

  computed: {
    ...mapGetters(["allBoats", "getTodosBarcosArt", "getCurrentType"]),

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    type: async function (valueType) {
      const boatsType = this.$refs.boatsTable.getBoatType(valueType);
      this.$refs.boatsTable.filterBoats(boatsType);
    },
  },
  methods: {

    valor_tasa() {
      return this.convert(this.editedItem.valor_tasa_anual);
    },
    getBase64(url) {
      return this.axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    },
    async generatePDF()
    {
      if(this.barco.status == 'active')
      {
        this.seeItemPatent(this.barco.id);
      }
    },
    CPAgeneratePDF() {


        let doc = new jsPDF();
        doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);
        doc.line(15, 15, 195, 15); // horizontal line
        doc.addImage(this.watermark, "PNG", 34, 83, 140, 140, "FAST", 180);
  
        doc.setFontSize(12).text("Gobernación", 40, 23);
        doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
        doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
        doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
        doc.line(15, 40, 195, 40); // horizontal line
        doc
          .setFontSize(14)
          .text(
            "CERTIFICADO DE PATENTE DE PESCA N° " + this.editedItem.consecutive,
            doc.internal.pageSize.width / 2,
            53,
            null,
            null,
            "center"
          );
  
        doc.setFontSize(12).text("Fecha de", 120, 23);
        doc.setFontSize(12).text("Aprobación:", 120, 27);
        doc.setFontStyle("italic").setFontSize(12).text("2020-01-29", 120, 31);
  
        doc.setFontSize(12).text("Código", 150, 23);
        doc.setFontStyle("italic").setFontSize(12).text("FO-MI-DP-15", 150, 27);
        var pageCount = doc.internal.getNumberOfPages();
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: 60,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            ["Tipo de Permiso", "Lugar de Solicitud", "Fecha de Expedición"],
          ],
          body: [
            [
              this.type == "art" ? "Artesanal" : "Industrial",
              this.lugar.nombre,
              this.barcoPatent.date_expedition_patent,
            ],
          ],
          theme: "grid",
        });
  
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Vigencia (meses)",
              "Fecha de inicio",
              "Fecha de vencimiento",
              "Valor Derechos Aplicables (pesos)",
            ],
          ],
          body: [
            [
              this.barcoPatent.validity_of_patent,
              this.barcoPatent.date_expedition_patent,
              this.barcoPatent.date_expiration_patent,
              Intl.NumberFormat('es-CO',{style:'currency',currency:'COP'}).format(Number(this.editedItem.valor_tasa_anual)),
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Valor Derechos Aplicables en Letras"]],
          body: [
            [
              this.editedItem.valor_tasa_anual != null
                ? this.valor_tasa(this.editedItem.valor_tasa_anual)
                : "-",
            ],
          ],
          theme: "grid",
        });
  
        doc
          .setFontSize(8)
          .text(
            "DE CONFORMIDAD CON LAS DISPOCISIONES DE LOS DECRETOS NÚMEROS 2256 DE 1991 Y 1300 DEL 2003 Y DEMÁS NORMAS \n VIGENTES, SE CONCEDE CERTIFICADO DE PATENTE DE PESCA MARÍTIMA A LA EMBARCACIÓN AL SERVICIO DE:",
            15,
            doc.previousAutoTable.finalY + 6
          );
  
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY + 13,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Permisionario y/o Titular del Permiso ", "Tipo de Pesquería"]],
          body: [
            [
              this.dataItem.requestable_type === requestables.FISHERMEN ? `${this.dataItem.requestable.full_name}` : this.company.name,
              this.barcoPatent.type_fishery.map((x) => x.pivot.description),
            ],
          ],
          theme: "grid",
        });
  
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Artes de Pesca", "Área de Operaciones"]],
          body: [
            [
              this.barcoPatent.fishing_arts.map((x) => x.pivot.description),
              this.barcoPatent.fishing_zone != null ? this.barcoPatent.fishing_zone : "-",
            ],
          ],
          theme: "grid",
        });
  
        doc
          .setFontSize(12)
          .text(
            "CARACTERÍSTICAS",
            doc.internal.pageSize.width / 2,
            doc.previousAutoTable.finalY + 10,
            null,
            null,
            "center"
          );
  
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY + 15,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Nombre de la Embarcación",
              "Bandera",
              "Eslora (m)",
              "Calado/Proyecto(m)",
              "Manga (m)",
            ],
          ],
          body: [
            [
              this.barcoPatent.boat_name,
              concepto.items_select_current_state_pavilion.find(
                (x) => x.abbr == this.barcoPatent.current_state_pavilion
              ) != undefined
                ? concepto.items_select_current_state_pavilion.find(
                    (x) => x.abbr == this.barcoPatent.current_state_pavilion
                  ).state
                : "-",
  
              this.barcoPatent.length_overall,
              this.barcoPatent.calado,
              this.barcoPatent.beam_extreme_breadth,
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Tipo de barco", "Registro Bruto (TRB)", "Regitro Neto (TRN)"]],
          body: [
            [
              this.barcoPatent.boat_type_state != null
                ? this.barcoPatent.boat_type_state
                : this.barcoPatent.boat_type != null
                ? this.barcoPatent.boat_type.nombre
                : "-",
              this.barcoPatent.gross_registered_tonnage,
              this.barcoPatent.net_registered_tonnage,
            ],
          ],
          theme: "grid",
        });
  
        doc.autoTable({
          tableLineWidth: 0.3,
          tableLineColor: 10,
          bodyStyles: {
            lineColor: 10,
            lineWidth: 0.3,
            fillColor: false,
          },
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "No. de Tripulantes",
              "No. de Pescadores",
              "Potencia del Motor",
              "No. de Matrícula",
              "Expedida Por",
            ],
          ],
          body: [
            [
              this.barcoPatent.number_of_crew,
              this.barcoPatent.number_of_fishermens,
              this.barcoPatent.main_engines_power,
              this.barcoPatent.registration_number,
              this.barcoPatent.expedition_by,
            ],
          ],
          theme: "grid",
        });
  
        doc
          .setFontSize(8)
          .text(
            "NOMBRE DEL GERENTE / OPERADOR / ARMADOR",
            15,
            doc.previousAutoTable.finalY + 55
          );
  
        doc.setDrawColor(0, 0, 0);
  
        doc.line(
          15,
          doc.previousAutoTable.finalY + 76,
          95,
          doc.previousAutoTable.finalY + 76
        );
  
        doc
          .setFontSize(12)
          .text(
            "" + this.barcoPatent.manager_operator,
            15,
            doc.previousAutoTable.finalY + 80
          );
        const legend = "SECRETARIO DE AGRICULTURA Y PESCA";
  
        doc.setFontSize(8).text(legend, 120, doc.previousAutoTable.finalY + 55);
  
        doc
          .setFontSize(12)
          .text(
            `${this.editedItem.nombre_secretario || ''}`,
            120,
            doc.previousAutoTable.finalY + 80
          );
  
        doc.line(
          120,
          doc.previousAutoTable.finalY + 76,
          200,
          doc.previousAutoTable.finalY + 76
        );
  
        doc.line(
          15,
          doc.previousAutoTable.finalY + 50,
          195,
          doc.previousAutoTable.finalY + 50
        );
  
        doc
          .setFontSize(8)
          .text("Observaciones:", 15, doc.previousAutoTable.finalY + 10);
  
        doc.setFontSize(8).fromHTML(
          `<small>${this.editedItem.concepto_recomendaciones || ''}</small>`,
          15,
          doc.previousAutoTable.finalY + 10,
          {
            // y coord
  
            width: "180", // max width of content on PDF
          },
          function (dispose) {
            // dispose: object with X, Y of the last line add to the PDF
            // this allow the insertion of new lines after html
            dispose;
          }
        );
  
        let pdfName = "Certificado - " + this.editedItem.consecutive + ".pdf";
  
        var i = 0;
        for (i; i < pageCount; i++) {
          doc.setPage(i);
          doc.text(
            10,
            10,
            "Pag " +
              doc.internal.getCurrentPageInfo().pageNumber +
              " de " +
              pageCount
          );
        }
        doc.save(pdfName);
    },
    Valid() {
      const pattern = /^[a-zA-Z0-9]+$/;
      this.matricula_add = this.matricula_add.toUpperCase();
      this.matricula_valid = pattern.test(this.matricula_add);
    },
    setItem(item) {
      this.barco = item;
      this.modal_matricula = true;
      this._id = item.id;
      this.nombre_boat = item.boat_name;
      this.matricula_add = item.registration_number;
      if(this.matricula_add != '')
        this.Valid();
      this.cod_tracing = item.cod_tracing;
    },
    editBoat: async function () {
      if (
        this.nombre_boat.length == 0 &&
        this.registration_number.length == 0 &&
        this.cod_tracing.length == 0
      ) {
        return false;
      }
      await this.axios
        .put(editBoatUrl + this._id, {
          boat_name: this.nombre_boat,
          registration_number: this.matricula_add,
          cod_tracing: this.cod_tracing,
        })
        .then(() => {
          this.nombre_boat = "";
          this.matricula_add = "";
          this.cod_tracing = "";
          this._id = "";
        });
      const boatsType = this.$refs.boatsTable.getBoatType();
      this.$refs.boatsTable.filterBoats(boatsType);
      this.modal_matricula = false;
    },
    async seeItem(item) {
      const { data } = (
        await axios.get(`${getBoatPath}${item.id}`, {
          headers: getHeader(),
        })
      )?.data;
      this.barco = data;
      this.concepto = await consultar.fetch();
      this.modal = true;
    },
    seeItemPatent: async function (id) {
      this.$store.commit('LOADER', true);
      //this.$store.commit("START_LOADER");

      this.download = false;

      var path = `${getConceptTechnicalArtPatent}?barco=${id}`;

      this.axios.get(path).then(async (response) => {
        if (response.status === 200) {
          var url = firmaUrl + response.data.data.firma_secretario;
          var url_fun = firmaUrl + response.data.funcionario.firma;
          //var url_rep = firmaUrl + response.data.data.firma_representante;


          this.firma_secre = await this.getBase64(url);
          this.firma_fun = await this.getBase64(url_fun);
          this.download = true;
          this.dataItem = response.data.data;
          this.company = this.dataItem.company;
          this.first_permission = response.data.first_permission;

          if (!this.dataItem.permission) {
            if (this.company.permissions.length > 0)
              this.permiso =
                this.company.permissions[this.company.permissions.length - 1];
          }
          else {
            this.permiso = this.dataItem.permission;
          }

          this.dataParent = {...this.dataItem, boat: this.dataItem.boat_unique_on_request};
          this.editedItem = {...this.dataItem};//{...this.editedItem, ...this.dataParent};
          this.tipo_solicitud = this.dataParent.request_type;

          this.funcionario = response.data.funcionario;
          this.lugar = this.dataItem.lugar;
          this.tipo_autorizacion = this.dataItem.tipo_autorizacion;
          this.certificado_expedido_por =
          this.dataItem.company.certificado_expedido_por;
  
          this.barcos = this.dataItem.boats;
          this.barcoPatent = this.dataParent.boat;


          if (this.dataItem.company.type_of_company == "UNO") {
            this.type_of_company = "NATURAL";
          } else if (this.dataItem.company.type_of_company == "DOS") {
            this.type_of_company = "JURIDICA";
          }


          this.CPAgeneratePDF();
        }
      });
    },
    Peticion: async function ({action, item, title}) {
      if (title === "CP") {
        this.dialogRad = true;
        this.$refs.setDataRad.setData(action, item, title, this.type);
        return;
      }
      const verif_req = (
        await axios.get(`${findRequestBoat}/${item.id}`, {
          headers: getHeader(),
        })
      )?.data;
      if (Object.keys(verif_req).length) {
        this.dialogRad = false;
        this.snackbar = true;
      } else {
        this.dialogRad = true;
        this.$refs.setDataRad.setData(action, item, title, this.type);
      }
    },
  },

  created: async function () {
    this.items = navegacion.items_navegation;
    this.escudo = await this.getBase64(escudogobernacion);
    this.watermark = await this.getBase64(watermark);
  },
};
</script>

<style lang="scss" scoped></style>
