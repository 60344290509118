<template>
  <v-card class="mx-auto"
  >
    <ComponentModalActivityLog
      v-on:handleClose="dialogSeeActivityLog = false;"
      :modal="dialogSeeActivityLog"
      type=""
      process="boats"
      :consecutive="consecutive"
      v-if="dialogSeeActivityLog"
    ></ComponentModalActivityLog>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [5,10,15]
      }"
      :headers="customHeaders || headers"
      :items="filtrado.data"
      :search="searchBoat"
      :server-items-length="filtrado.total"
      v-on:update:options="paginate"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Embarcaciones</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{}">
              <v-spacer></v-spacer>
              <export-excel
                :fetch.sync="exportBoats"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                :fields="json_fields"
                worksheet="Embarcaciones"
                name="Embarcaciones.xls"
                style="ml-4 margin-bottom:10px"
              >
                <v-btn
                  color="green darken-4 ml-4 white--text"
                  small
                  dark
                  class="mb-2"
                  v-if="type == 'ind' 
                  ? $can('exportarExcelIndustriales')
                  : $can('exportarExcelArtesanales')
                  "
                >
                  <v-icon small class="mr-2">fa fa-file-excel</v-icon>
                  Exportar
                </v-btn>
              </export-excel>
              <v-text-field
                v-model="searchBoat"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                v-debounce:200ms="search"
              ></v-text-field>
            </template>
          </v-dialog>
        </v-toolbar>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-4 mb-4" :color="filterFishery ? 'success': 'default'" v-on="on">
              {{ nameFilterFishery }}
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor: pointer">
              <v-list-item-content @click="setFilterFishery()">
                <v-list-item-title>Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="type in concepto.items_select_types_of_fishing"
              :key="type.codigo"
              :value="type"
              style="cursor: pointer"
            >
              <v-list-item-content @click="setFilterFishery(type)">
                <v-list-item-title> {{ type.nombre }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor: pointer">
              <v-list-item-content
                @click="setFilterFishery({ nombre: 'Otros', codigo: 'CINCO' })"
              >
                <v-list-item-title>Otros</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-4 mb-4" :color="filterVigency ? 'success' : 'default'" v-on="on">
              {{ nameFilterVigency }}
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor: pointer">
              <v-list-item-content @click="setFilterVigency()">
                <v-list-item-title>Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="type in concepto.items_vigency_permi"
              :key="type.codigo"
              style="cursor: pointer"
            >
              <v-list-item-content @click="setFilterVigency(type)">
                <v-list-item-title> {{ type.nombre }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-4 mb-4" :color="filterVigencyPatent ? 'success' : 'default'" v-on="on">
              {{ nameFilterPatent }}
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor: pointer">
              <v-list-item-content @click="setFilterPatent()">
                <v-list-item-title>Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="type in concepto.items_patent"
              :key="type.codigo"
              style="cursor: pointer"
            >
              <v-list-item-content @click="setFilterPatent(type)">
                <v-list-item-title> {{ type.nombre }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-badge class="ml-2" :content="filterApplied" :value="filterApplied" color="green" overlap>
          <v-icon large> mdi-filter </v-icon>
        </v-badge>
      </template>
      <template v-slot:item.boat_name="{ item }">
        {{ item.boat_name | uppercase }}
      </template>
      <template v-slot:item.id_propietario="{ item }">
        <span>
          {{
            item.owner_identification !== null
              ? item.owner_identification
              : "-"
          }}.
        </span>
      </template>
      <template v-slot:item.pesqueria="{ item }">
        <span v-for="item_ in getTypeFishery(item)" :key="item_.id">
          {{ item_.pivot.description }}.
        </span>
      </template>
      <template v-slot:item.company="{ item }">
        <template v-if="item.company == null">Sin permisionariosss</template>
        <template v-else>{{item.company.name}}</template>
      </template>
      <template v-slot:item.patente="{ item }">
        <v-chip small :color="getColor(item)" :title="getStatusDesc(item)" dark
          >{{
            item.status === "noPatent"
              ? "Sin Patente"
              : item.date_expiration_patent
          }}
        </v-chip>
      </template>
      <template v-slot:item.inspection="{ item }">
        <v-chip
          small
          :color="getColor(item.current_technical_inspection)"
          :title="getStatusDesc(item.current_technical_inspection)"
          dark
          >{{
            !(item.current_technical_inspection || {}).status
              ? "Sin inspección"
              : item.current_technical_inspection.expiration_date
          }}
        </v-chip>
      </template>
      <template v-slot:item.permiso="{ item }">
        <v-chip
          small
          :color="getColor((item.company || {}).current_permit)"
          :title="getStatusDesc((item.company || {}).current_permit)"
          dark
        >
          {{ datePermit((item.company || {}).current_permit) }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="seeTracking(item)"  v-if="type == 'ind'
                      ? $can('verConsultasIndustriales')
                      : type == 'art'
                      ? $can('verConsultasArtesanales')
                      : type == 'dep'
                      ? $can('verConsultasDeportivas')
                      : ''"
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-crosshairs-gps</v-icon>
                  Ver Seguimiento
                </v-list-item-title>
              </v-list-item> 
            <v-list-item @click="$emit('onShowBoat', item)" 
              v-if="type == 'ind' 
                ? $can('verDetalleEmbarcacionesIndustriales')
                : $can('verDetalleEmbarcacionesArtesanales')
              ">
              <v-list-item-title>
                <v-icon small class="mr-2">remove_red_eye</v-icon>
                Información
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="seeActivityLog(item.id)"> 
              <v-list-item-title>
                <v-icon class="mr-2" small>fas fa-history</v-icon>
                Historial de actividades
              </v-list-item-title>
            </v-list-item>
            <template
                v-if="type == 'ind' 
                ? $can('gestionarProcesosIndustriales')
                : $can('gestionarProcesosArtesanales')
              ">
              <v-list-item
                @click="$emit('onAddInformation', item)"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">fa-edit</v-icon>
                  Editar Inf. Básica
                </v-list-item-title>
              </v-list-item>
              <template v-if=" (
                ['green', 'orange'].includes(getColor((item.company || {}).current_permit)) || 
                item.fishermans_vig.length > 0
              )  &&  ['green', 'orange'].includes(getColor(item.current_technical_inspection))"> 
                <v-list-item
                  v-if="
                    (getColor((item.company || {}).current_permit) == 'green' || item.type_boat == 'ART')
                    && ($can('gestionarProcesosIndustriales') || $can('gestionarProcesosArtesanales'))
                    && parseInt(item.estado) == 1 
                    && (item.company !== null || item.fishermans.length || item.fishermans_ind.length)"
                  @click="$emit('onRequest', { action: 'pp', item, title: 'CP' })"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>check</v-icon>
                    Patente de Pesca
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item title="No cumple con los requisitos para realizar la patente" style="background: #e9e5e5;cursor: not-allowed;"
                >
                  <v-list-item-title>
                    <v-icon small class="mr-2">fa-edit</v-icon>
                    Patente de Pesca
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item
                v-if="(getColor((item.company || {}).current_permit) == 'green') && item.estado == 1"
                @click="$emit('onRequest', { action: 'rm', item, title: 'CT' })"
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>compare_arrows</v-icon>
                  Reemplazo de Motonave
                </v-list-item-title>
              </v-list-item> 
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import companyTypes from "../enums/companyTypes";
import getFields from "../services/exportboats";
import { concepto } from "../services/variablesconcepto";
import ComponentModalActivityLog from "../components/ComponentModalActivityLog";

export default {
  components: {ComponentModalActivityLog},
  props: ["type", "customHeaders"],
  computed: {
    ...mapGetters(["allBoats", "getCurrentType"]),
    filterApplied() {
      return (
        Number(Boolean(this.filterVigency)) +
        Number(Boolean(this.filterVigencyPatent)) +
        Number(Boolean(this.filterFishery))
      );
    },

    filtrado: {
      set(value) {
        return value;
      },
      get() {
        return this.allBoats;
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  data() {
    return {
      concepto: concepto,
      dialog: false,
      searchBoat: "",
      json_fields: {},
      headers: [
        { text: "Nombre", value: "boat_name" },
        {
          text: "No. Matricula",
          align: "left",
          value: "registration_number",
        },
        {
          text: "Permisionario",
          value: "company",
          sortable: false,
        },
        { text: "Propietario", value: "owner_name", sortable: false },
        {
          text: "No. de Identificación del Propietario",
          value: "owner_identification",
          sortable: false,
        },
        { text: "Tipos Pesque.", value: "pesqueria", sortable: false },
        { text: "Permiso", value: "permiso"},
        { text: "Patente", value: "patente"},
        { text: "Inspección", value: "inspection"},
        { text: "Acciones", value: "action", sortable: false },
      ],
      filterVigency: null,
      filterVigencyPatent: null,
      filterFishery: null,
      nameFilterFishery: "Tipos de Pesqueria",
      nameFilterVigency: "Vigencia del Permiso",
      nameFilterPatent: "Vigencia de la patente",
      dialogSeeActivityLog: false,
      consecutive: ''
    };
  },

  created: async function () {
    this.json_fields = (await getFields())?.json_fields;
  },
  methods: {
    seeTracking(item)
    {
      this.$router.push({
        path: `/track-fishing/`+item.cod_tracing
        ,
      });
    },
    seeActivityLog: async function (consecutive) {
      this.consecutive = consecutive;
      this.dialogSeeActivityLog = true;
    },
    getTypeFishery(item) {
      return item.company?.current_permit?.type_fishery || [];
    },
    getColor(item) {
      const status = item?.status;
      return (
        {
          active: "green",
          expired: "red",
          alert: "orange",
        }[status] || "red"
      );
    },
    getStatusDesc(item) {
      const status = item?.status;
      return (
        {
          active: "Vigente",
          expired: "Vencido",
          canceled: "Cancelado",
          alert: "Por Vencer",
        }[status] || "No tiene"
      );
    },
    search() {
      this.$store.commit("LOADER", undefined);
      const boatType = this.getBoatType();
      this.filterBoats(boatType);
    },
    setFilterFishery(type = null) {
      this.nameFilterFishery = type?.nombre || "Tipos de pesqueria";
      this.filterFishery = type?.codigo;
      const boatType = this.getBoatType();
      this.filterBoats(boatType);
    },
    setFilterVigency(type = null) {
      this.nameFilterVigency = type?.nombre || "Vigencia del permiso";
      this.filterVigency = type?.codigo;
      const boatType = this.getBoatType();
      this.filterBoats(boatType);
    },
    setFilterPatent(type = null) {
      this.nameFilterPatent = type?.nombre || "Vigencia de la patente";
      this.filterVigencyPatent = type?.codigo;
      const boatType = this.getBoatType();
      this.filterBoats(boatType);
    },
    getBoatType(type = null) {
      return {
        art: companyTypes.ARTESANAL,
        ind: companyTypes.INDUSTRIAL,
      }[type || this.type];
    },
    async filterBoats(boatType) {
      this.filtrado = await this.getBoats(boatType, {
        page: this.currentPage,
        itemsPerPage: this.currentItemsPerPage,
        ...this.getCurrentFilter(),
      });
    },
    getCurrentFilter() {
      return {
        ...(this.filterVigency && { vigency: this.filterVigency }),
        ...(this.filterVigencyPatent && {
          vigencyPatent: this.filterVigencyPatent,
        }),
        ...(this.filterFishery && { fishery: this.filterFishery }),
        ...(this.searchBoat && { search: this.searchBoat }),
        hasInspection: true,
      };
    },
    paginate: async function (pagination) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.currentPage = page;
      this.currentItemsPerPage = itemsPerPage;
      this.filtrado = await this.getBoats(this.getBoatType(), {
        page: this.currentPage,
        itemsPerPage,
        ...this.getCurrentFilter(),
        ...(sortBy[0] && {
          [`${sortBy[0]}`]: sortDesc[0],
        }),
      });
    },
    async getBoats(type, options) {
      await this.$store.dispatch("getBoats", { type, options });
      return this.allBoats;
    },
    async exportBoats() {
      const companyType = this.getBoatType();
      return (
        await this.getBoats(companyType, {
          page: 1,
          itemsPerPage: this.currentItemsPerPage,
          ...this.getCurrentFilter(),
          excel: true,
        })
      )?.data;
    },
    startDownload() {
      this.currentItemsPerPage = this.filtrado.total;
     this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
    },
    finishDownload() {
      //this.$store.commit("LOADER", false);
      this.$store.commit("STOP_LOADER");
      this.currentItemsPerPage = 10;
      const boatType = this.getBoatType();
      this.filterBoats(boatType);
    },
    datePermit(permission) {
      if (permission) {
        if (permission.effective_inactivation_date) {
          return permission.effective_inactivation_date;
        } else {
          return "Sin Permiso";
        }
      } else {
        return "Sin Permiso";
      }
    },
  },

};
</script>

<style></style>
