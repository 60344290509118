export const navegacion = {
  items_navegation: [
    {
      text: 'Lista Permisionario',
      icon: 'mdi-clipboard-list',
      redirect: 'companies-',
      permission: ["verPermisionariosArtesanales","verPermisionariosIndustriales"]
    },
    {
      text: 'Procesos Permisionario',
      icon: 'mdi-clipboard-list',
      redirect: 'requests-companies-',
      permission: ["verProcedimientosArtesanales","verProcedimientosIndustriales"]
    },
    {
      text: 'Lista Pescadores',
      icon: 'mdi-fish',
      redirect: 'fishermen-',
      permission: ["verPermisionariosIndustriales","verPermisionariosArtesanales","verLista_PescadoresDeportivos"]
    },
    {
      text: 'Procesos Pescadores',
      icon: 'mdi-fish',
      redirect: 'request-fisherman-',
      permission: ["Gestionar_Pescador_Industrial","Gestionar_Pescador_Artesanal","Gestionar_Pescador_Deportivo"]
    },
    {
      text: 'Embarcaciones',
      icon: 'mdi-ferry',
      redirect: 'boats-',
      permission: ["verEmbarcacionesIndustriales", "verEmbarcacionesArtesanal"]
    }
  ]
};
