export const requestTypes = {
  NUEVO: "UNO",
  PRORROGA: "DOS",
  VINCULACION_EMBARCACION: "TRES",
  DESVINCULACION_EMBARCACION: "CUATRO",
  REMPLAZO_EMBARCACION: "CINCO",
  CANCELACION_PERMISO: "SEIS",
  PATENTE_PESCA: "SIETE",
  REACTIVACION_PERMISO: "OCHO",
};

export const abreviatureTypeRequest = {
  NUEVO: "UNO",
  PRORROGA: "p",
  VINCULACION_EMBARCACION: "v",
  DESVINCULACION_EMBARCACION: "d",
  REMPLAZO_EMBARCACION: "rm",
  CANCELACION_PERMISO: "c",
  PATENTE_PESCA: "pp",
  REACTIVACION_PERMISO: "r",
};
export const fishermanRequestTypes = {
  NUEVO: "UNO",
  PRORROGA: "DOS",
  DUPLICADO: "TRES",
  CANCELACION_PERMISO: "CUATRO",
  REACTIVACION_PERMISO: "CINCO",
  
};
export const shortTypeRequest = {
  NUEVO: "UNO",
  PRORROGA: "p",
  DUPLICADO: "d",
  CANCELACION_PERMISO: "c",
  REACTIVACION_PERMISO: "r",
};
Object.freeze(requestTypes);
Object.freeze(abreviatureTypeRequest);
Object.freeze(fishermanRequestTypes);
Object.freeze(shortTypeRequest);